import { AdvertisementViewModel } from '@/entities/AdvertisementViewModel';
import { DialogueThemeEntity } from '../DialogueTheme/DialogueThemeEntity';
import CustomMenuItemTranslationsViewModel from './CustomMenuItemTranslationsViewModel';
import { t } from '@/main';
import CustomBrandingViewModel from './CustomBrandingViewModel';

export class ApplicationInstanceModel {
  public static createEmpty(): ApplicationInstanceModel {
    return new ApplicationInstanceModel();
  }
  constructor(
    public Id: number = 0,
    public ApplicationCode: string = '',
    public Name: string = '',
    public Description: string = '',
    public SortOrder: number = 0,
    public ListLogoUrl: string = '',
    public EnableNewsFeeds: boolean = false,
    public EnableNotes: boolean = false,
    public EnableActivityFeeds: boolean = false,
    public EnableGamification: boolean = false,
    public EnableRegistrationSystem: boolean = false,
    public RegistrationTemplateId: number = 0,
    public EnableMessagingfeature: boolean = false,
    public EnableMyExtraInformation: boolean = false,
    public EnableParticipantList: boolean = false,
    public EnableMyProgram: boolean = false,
    public EnableProgram: boolean = false,
    public EnableInformation: boolean = false,
    public EnableDialogue: boolean = false,
    public EnableEventWebPage: boolean = false,
    public EnableZapier: boolean = false,
    public IsEditable: number = 0,
    public OverrideParticipantLimit: boolean = false,
    public IsDemoEvent: boolean = false,
    public IsSuperAdminHiddenEvent: boolean = false,
    public EnableStripeTestMode: boolean = false,
    public ApplicationHasEnabledParticipantList: boolean = false,
    public EnableUserEditedParticipant: boolean = false,
    public ApplicationHasEnabledUserEditedParticipant: boolean = false,
    public RequiresNameToJoin: boolean = false,
    public ApplicationHasEnableRequiresNameToJoin: boolean = false,
    public Latitude: number = 0,
    public Longitude: number = 0,
    public Address: string = '',
    public City: string = '',
    public StartDate = new Date(),
    public EndDate = new Date(),
    public ApplicationCreateAccountTypeId: number = 4,
    public RequiredEventTicket: boolean = false,
    public RequireEventCheckIn: boolean = false,
    public DepartmentId: number = 0,
    public DepartmentName: string =  t('EventSetting.Unspecified').toString(),
    public IanaTimeZone: string = '',
    public WindowsTimeZone: string = '',
    public ApplicationId: number = 0,
    public ParticipantsCount: number = 0,
    public ParticipantJoinedCount: number = 0 ,
    public QRCodeUrl: string = '',
    public RegistrationTemplateName: string = '',
    public State: number = 0,
    public FreezeEventDate = new Date(),
    public DisplayParticipantsInApp: boolean = false,
    public HideParticipantsEmailInApp: boolean = false,
    public CreatedUtc = new Date(),
    public CreatedBy: string = '' ,
    public EnableParticipantSelfCheckIn: boolean = false,
    public EnableLiveStreaming: boolean = false,
    public IsEventDeepLinkingSettingsSaved: boolean = false,
    public EventInvitationLink: string = '',
    public EventInvitationLinkWithoutCode: string = '',
    public IncludeEventCodeInLink: boolean = false,
    public ApplicationHasEnabledProgram: boolean = false,
    public ApplicationHasEnabledInformation: boolean = false,
    public ApplicationHasEnabledDialogue: boolean = false,
    public ApplicationHasEnableMessagingfeature: boolean = false,
    public ApplicationHasEnableActivityFeed: boolean = false,
    public IsEventBriteActive: boolean = false,
    public IsLyytiActive: boolean = false,
    public IsRegistrationSystemActive: boolean = false,
    public IsLiveStreamingActive: boolean = false,
    public LocationType: number = 1,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public ApplicationPremiumFeatureList: ApplicationPremiumFeatureList[] = [],
    public IsVisibilityPrivate: boolean = false,
    public EventAccessLinkQRCodeUrl: string = '',
    public IsApplicationLegacyModel: boolean = false,
    public IsDatesShouldbeVisible: boolean = false,
    public IsQuotaExceed: boolean = false,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public AdvertisementViewModel: AdvertisementViewModel[] = [],
    public EnableImageArchive: boolean = false,
    public DialogueThemes: DialogueThemeEntity[] = [],
    public DialogueThemeId: number = 0,
    public MarkAsDefaultTheme: boolean = false,
    public ApplyClientSideDialogueThemes: boolean = false,
    public EventAccessLinkAutoRedirectToMobileApp: boolean = false,
    public NeedCustomMenuNames: boolean = false,
    public LanguageId: number = 0,
    public CustomMenuItemTranslations: CustomMenuItemTranslationsViewModel[] = [],
    public IsParticipantQRVisible: boolean = false,
    public EnableWebApp: boolean = false,
    public IsCustomBranding: boolean = false,
    public CustomBrandingTheme: CustomBrandingViewModel = CustomBrandingViewModel.createEmpty(),
  ) { }
}

export class ApplicationPremiumFeatureList {
  public static createEmpty(): ApplicationPremiumFeatureList {
    return new ApplicationPremiumFeatureList();
  }
  constructor(
    public ApplicationId: number = 0,
    public Id: number =0,
    public IsActive: boolean = false,
    public IsVisible: boolean = false,
    public PremiumFeatureId: number = 0,
    public PremiumFeatureName: string ='',
    public IsSubscriptionBased: boolean = false,
    public Datetime = new Date(),
    public IconUrl: string = '',
  ) { }
}

