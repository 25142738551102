import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "webViewCot" }
const _hoisted_2 = { class: "left-web-view-block border-radius-10" }
const _hoisted_3 = { class: "width60 menu-container" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "width40 position-relative" }
const _hoisted_6 = { class: "menu-bar-left" }
const _hoisted_7 = { class: "webViewBlock border-radius-10" }
const _hoisted_8 = { class: "body-view" }
const _hoisted_9 = { class: "footerView" }
const _hoisted_10 = {
  width: "36",
  height: "7",
  viewBox: "0 0 36 7",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_11 = ["fill"]
const _hoisted_12 = ["fill"]
const _hoisted_13 = {
  width: "36",
  height: "7",
  viewBox: "0 0 36 7",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_14 = ["fill"]
const _hoisted_15 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "headerView justify-content-space-between",
            style: _normalizeStyle({'backgroundColor':_ctx.localCustomBrandingPreviewData.PrimaryBackgroundColor})
          }, [
            _createElementVNode("img", { src: _ctx.logoPlaceholder }, null, 8, _hoisted_4),
            _createElementVNode("span", {
              class: "viewLine sp-half",
              style: _normalizeStyle({'backgroundColor':_ctx.localCustomBrandingPreviewData.PrimaryFontColor})
            }, null, 4)
          ], 4),
          _createElementVNode("div", {
            class: "menu-bar-right",
            style: _normalizeStyle({'backgroundColor':_ctx.localCustomBrandingPreviewData.MenuBackgroundColor})
          }, [
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalMemuItem, (item, index) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: index,
                  class: "viewLine sp-full",
                  style: _normalizeStyle({'backgroundColor': _ctx.localCustomBrandingPreviewData.MenuFontColor})
                }, null, 4))
              }), 128))
            ])
          ], 4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "overlay" }, null, -1)),
          _createElementVNode("div", {
            class: "headerView",
            style: _normalizeStyle({'backgroundColor':_ctx.localCustomBrandingPreviewData.PrimaryBackgroundColor})
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("span", { class: "viewLine sp-half visibility-hidden" }, null, -1)
          ]), 4),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "viewLine sp-full" }, null, -1)),
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-area" }, null, -1)),
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (item, index) => {
                return _createElementVNode("span", {
                  key: index,
                  class: "viewLine sp-full left-container-view-line"
                })
              }), 64))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", {
          class: "headerView justify-content-center",
          style: _normalizeStyle({'backgroundColor':_ctx.localCustomBrandingPreviewData.PrimaryBackgroundColor})
        }, [
          _createElementVNode("span", {
            class: "viewLine sp-half",
            style: _normalizeStyle({'backgroundColor':_ctx.localCustomBrandingPreviewData.PrimaryFontColor})
          }, null, 4)
        ], 4),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalBodyContentLines, (item, index) => {
            return (_openBlock(), _createElementBlock("span", {
              key: index,
              class: "viewLine sp-full"
            }))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", null, [
            (_openBlock(), _createElementBlock("svg", _hoisted_10, [
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M1.31212 0L0 1.5094L3.77355 4.7897L5.28297 6.1018L6.59509 4.5924L8.13456 2.8214L10.3988 4.7897L11.9082 6.1018L13.2203 4.5924L14.7584 2.823L17.0209 4.7897L18.5303 6.1018L19.8424 4.5924L21.8106 2.3283L20.3012 1.0162L18.333 3.2803L14.5594 0L13.2473 1.5094L13.249 1.5109L11.7109 3.2803L7.93736 0L7.05395 1.0163L5.08567 3.2803L1.31212 0Z",
                fill: _ctx.localCustomBrandingPreviewData.CTABackgroundColor
              }, null, 8, _hoisted_11),
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M14.5621 0L13.25 1.5094L17.0236 4.7897L18.533 6.1018L19.8451 4.5924L21.3846 2.8214L23.6488 4.7897L25.1582 6.1018L26.4703 4.5924L28.0084 2.823L30.2709 4.7897L31.7803 6.1018L33.0924 4.5924L35.0606 2.3283L33.5512 1.0162L31.583 3.2803L27.8094 0L26.4973 1.5094L26.499 1.5109L24.9609 3.2803L21.1874 0L20.304 1.0163L18.3357 3.2803L14.5621 0Z",
                fill: _ctx.localCustomBrandingPreviewData.CTABackgroundColor
              }, null, 8, _hoisted_12)
            ]))
          ]),
          _createElementVNode("div", {
            class: "filled-btn",
            style: _normalizeStyle({'background': _ctx.localCustomBrandingPreviewData.CTABackgroundColor})
          }, [
            (_openBlock(), _createElementBlock("svg", _hoisted_13, [
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M1.31212 0L0 1.5094L3.77355 4.7897L5.28297 6.1018L6.59509 4.5924L8.13456 2.8214L10.3988 4.7897L11.9082 6.1018L13.2203 4.5924L14.7584 2.823L17.0209 4.7897L18.5303 6.1018L19.8424 4.5924L21.8106 2.3283L20.3012 1.0162L18.333 3.2803L14.5594 0L13.2473 1.5094L13.249 1.5109L11.7109 3.2803L7.93736 0L7.05395 1.0163L5.08567 3.2803L1.31212 0Z",
                fill: _ctx.localCustomBrandingPreviewData.CTAFontColor
              }, null, 8, _hoisted_14),
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M14.5621 0L13.25 1.5094L17.0236 4.7897L18.533 6.1018L19.8451 4.5924L21.3846 2.8214L23.6488 4.7897L25.1582 6.1018L26.4703 4.5924L28.0084 2.823L30.2709 4.7897L31.7803 6.1018L33.0924 4.5924L35.0606 2.3283L33.5512 1.0162L31.583 3.2803L27.8094 0L26.4973 1.5094L26.499 1.5109L24.9609 3.2803L21.1874 0L20.304 1.0163L18.3357 3.2803L14.5621 0Z",
                fill: _ctx.localCustomBrandingPreviewData.CTAFontColor
              }, null, 8, _hoisted_15)
            ]))
          ], 4)
        ])
      ])
    ])
  ]))
}