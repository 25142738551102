import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, normalizeStyle as _normalizeStyle, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "app-branding-parent-container" }
const _hoisted_2 = { class: "app-branding-heading primary-heading" }
const _hoisted_3 = { class: "app-branding-item-container" }
const _hoisted_4 = { class: "display-flex" }
const _hoisted_5 = { class: "description display-flex" }
const _hoisted_6 = { class: "description margin-left-30 display-flex" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "webThemeBlock" }
const _hoisted_9 = { class: "formInput dvFlex" }
const _hoisted_10 = { class: "formInline" }
const _hoisted_11 = { class: "margin-top-10 margin-bottom-15" }
const _hoisted_12 = { class: "dvFlex formInput align-items-baseline" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "margin-bottom-0 bold-text" }
const _hoisted_16 = { class: "dvFlex" }
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = { class: "description" }
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = { class: "description" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "bold-text" }
const _hoisted_23 = { class: "dvFlex" }
const _hoisted_24 = { class: "form-group" }
const _hoisted_25 = { class: "description" }
const _hoisted_26 = { class: "form-group" }
const _hoisted_27 = { class: "description" }
const _hoisted_28 = { class: "row" }
const _hoisted_29 = { class: "bold-text" }
const _hoisted_30 = { class: "dvFlex" }
const _hoisted_31 = { class: "form-group" }
const _hoisted_32 = { class: "description" }
const _hoisted_33 = { class: "form-group" }
const _hoisted_34 = { class: "description" }
const _hoisted_35 = {
  key: 0,
  class: "webViewCot"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageContainer = _resolveComponent("ImageContainer")!
  const _component_Twitter = _resolveComponent("Twitter")!
  const _component_CustomBrandingThemePreview = _resolveComponent("CustomBrandingThemePreview")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("EventSetting.Customization.AppBranding")), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localIsCustomBranding) = $event)),
              type: "radio",
              name: "selectAppBrandingType",
              class: "radio-button",
              value: false,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.IsCustomBranding(false)))
            }, null, 512), [
              [_vModelRadio, _ctx.localIsCustomBranding]
            ]),
            _createTextVNode(_toDisplayString(_ctx.$t("EventSetting.Customization.DefaultBranding")), 1)
          ]),
          _createElementVNode("label", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localIsCustomBranding) = $event)),
              type: "radio",
              name: "selectAppBrandingType",
              class: "radio-button",
              value: true,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.IsCustomBranding(true)))
            }, null, 512), [
              [_vModelRadio, _ctx.localIsCustomBranding]
            ]),
            _createTextVNode(_toDisplayString(_ctx.$t("EventSetting.Customization.CustomBranding")), 1)
          ])
        ]),
        (_ctx.localIsCustomBranding)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_ImageContainer, {
                          "image-container-data": _ctx.lightModeLogoData,
                          onImageContainerClicked: _cache[4] || (_cache[4] = ($event: any) => (_ctx.LightLogoClicked(true)))
                        }, null, 8, ["image-container-data"]),
                        _createVNode(_component_ImageContainer, {
                          "image-container-data": _ctx.darkModeLogoData,
                          onImageContainerClicked: _cache[5] || (_cache[5] = ($event: any) => (_ctx.LightLogoClicked(false)))
                        }, null, 8, ["image-container-data"])
                      ]),
                      _createElementVNode("label", {
                        class: "warning-message-tab-view small-description",
                        innerHTML: _ctx.GetLogoWarningMessage()
                      }, null, 8, _hoisted_13)
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-md-7", {'width50': _ctx.isTabView, 'width100': _ctx.isMobileView}])
                      }, [
                        _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t("EventSetting.Customization.PrimaryColors")), 1)
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-md-5", {'width50': _ctx.isTabView, 'width100': _ctx.isMobileView}])
                      }, [
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t("Background")), 1),
                            _createElementVNode("div", {
                              class: "palette-container",
                              style: _normalizeStyle({'backgroundColor':_ctx.localCustomBrandingThemeData.PrimaryBackgroundColor})
                            }, [
                              (!_ctx.isPaletteSelected[0])
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: "optBlock",
                                    onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.TogglePalette(0)), ["stop"]))
                                  }))
                                : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                    key: 1,
                                    modelValue: _ctx.primaryBackgroundColor,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.primaryBackgroundColor) = $event))
                                  }, null, 8, ["modelValue"])), [
                                    [_directive_click_outside, _ctx.HideAllPalattes]
                                  ])
                            ], 4)
                          ]),
                          _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t("Font")), 1),
                            _createElementVNode("div", {
                              class: "palette-container",
                              style: _normalizeStyle({'backgroundColor':_ctx.localCustomBrandingThemeData.PrimaryFontColor})
                            }, [
                              (!_ctx.isPaletteSelected[1])
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: "optBlock",
                                    onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.TogglePalette(1)), ["stop"]))
                                  }))
                                : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                    key: 1,
                                    modelValue: _ctx.primaryFontColor,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.primaryFontColor) = $event))
                                  }, null, 8, ["modelValue"])), [
                                    [_directive_click_outside, _ctx.HideAllPalattes]
                                  ])
                            ], 4)
                          ])
                        ])
                      ], 2)
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-md-7", {'width50': _ctx.isTabView, 'width100': _ctx.isMobileView}])
                      }, [
                        _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t("EventSetting.Customization.MenuColrs")), 1)
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-md-5", {'width50': _ctx.isTabView, 'width100': _ctx.isMobileView}])
                      }, [
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("div", _hoisted_24, [
                            _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("Background")), 1),
                            _createElementVNode("div", {
                              class: "palette-container",
                              style: _normalizeStyle({'backgroundColor':_ctx.localCustomBrandingThemeData.MenuBackgroundColor})
                            }, [
                              (!_ctx.isPaletteSelected[2])
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: "optBlock",
                                    onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.TogglePalette(2)), ["stop"]))
                                  }))
                                : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                    key: 1,
                                    modelValue: _ctx.menuBackgroundColor,
                                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.menuBackgroundColor) = $event))
                                  }, null, 8, ["modelValue"])), [
                                    [_directive_click_outside, _ctx.HideAllPalattes]
                                  ])
                            ], 4)
                          ]),
                          _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t("Font")), 1),
                            _createElementVNode("div", {
                              class: "palette-container",
                              style: _normalizeStyle({'backgroundColor':_ctx.localCustomBrandingThemeData.MenuFontColor})
                            }, [
                              (!_ctx.isPaletteSelected[3])
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: "optBlock",
                                    onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (_ctx.TogglePalette(3)), ["stop"]))
                                  }))
                                : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                    key: 1,
                                    modelValue: _ctx.menuFontColor,
                                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.menuFontColor) = $event))
                                  }, null, 8, ["modelValue"])), [
                                    [_directive_click_outside, _ctx.HideAllPalattes]
                                  ])
                            ], 4)
                          ])
                        ])
                      ], 2)
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-md-7", {'width50': _ctx.isTabView, 'width100': _ctx.isMobileView}])
                      }, [
                        _createElementVNode("label", _hoisted_29, [
                          _createTextVNode(_toDisplayString(_ctx.$t("EventSetting.Customization.CTAButonColors")) + " ", 1),
                          _createElementVNode("i", {
                            class: "fa fa-info-circle margin-left-5",
                            "aria-hidden": "true",
                            onMouseover: _cache[14] || (_cache[14] = ($event: any) => (_ctx.GetCTABtnInformationString(true))),
                            onMouseleave: _cache[15] || (_cache[15] = ($event: any) => (_ctx.GetCTABtnInformationString(false)))
                          }, null, 32)
                        ]),
                        _withDirectives(_createElementVNode("div", { class: "information-icon-div" }, _toDisplayString(_ctx.$t('EventSetting.Customization.CTABtnTooltip')), 513), [
                          [_vShow, _ctx.showCTABtnInformationString]
                        ])
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-md-5", {'width50': _ctx.isTabView, 'width100': _ctx.isMobileView}])
                      }, [
                        _createElementVNode("div", _hoisted_30, [
                          _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t("Background")), 1),
                            _createElementVNode("div", {
                              class: "palette-container",
                              style: _normalizeStyle({'backgroundColor':_ctx.localCustomBrandingThemeData.CTABackgroundColor})
                            }, [
                              (!_ctx.isPaletteSelected[4])
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: "optBlock",
                                    onClick: _cache[16] || (_cache[16] = _withModifiers(($event: any) => (_ctx.TogglePalette(4)), ["stop"]))
                                  }))
                                : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                    key: 1,
                                    modelValue: _ctx.ctaBackgroundColor,
                                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.ctaBackgroundColor) = $event))
                                  }, null, 8, ["modelValue"])), [
                                    [_directive_click_outside, _ctx.HideAllPalattes]
                                  ])
                            ], 4)
                          ]),
                          _createElementVNode("div", _hoisted_33, [
                            _createElementVNode("label", _hoisted_34, _toDisplayString(_ctx.$t("Font")), 1),
                            _createElementVNode("div", {
                              class: "palette-container",
                              style: _normalizeStyle({'backgroundColor':_ctx.localCustomBrandingThemeData.CTAFontColor})
                            }, [
                              (!_ctx.isPaletteSelected[5])
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: "optBlock",
                                    onClick: _cache[18] || (_cache[18] = _withModifiers(($event: any) => (_ctx.TogglePalette(5)), ["stop"]))
                                  }))
                                : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                    key: 1,
                                    modelValue: _ctx.ctaFontColor,
                                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.ctaFontColor) = $event))
                                  }, null, 8, ["modelValue"])), [
                                    [_directive_click_outside, _ctx.HideAllPalattes]
                                  ])
                            ], 4)
                          ])
                        ])
                      ], 2)
                    ])
                  ]),
                  (!_ctx.isTabView && !_ctx.isTabLandscapeView)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                        _createVNode(_component_CustomBrandingThemePreview, { "custom-branding-preview-data": _ctx.localCustomBrandingThemeData }, null, 8, ["custom-branding-preview-data"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}