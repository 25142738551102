
import { ApplicationInstanceModel } from '@/entities/EventSetting/ApplicationInstanceModel';
import CustomMenuItemTranslations from '@/entities/EventSetting/CustomMenuItemTranslationsViewModel';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { Component, toNative, Vue, Watch } from 'vue-facing-decorator';
import LanguagesViewModel from '@/entities/EventSetting/LanguagesViewModel';
import CustomMenuDefaultTranslationsViewModel from '@/entities/EventSetting/CustomMenuDefaultTranslationsViewModel';
import Common from '@/helper/Common';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { StoreHelper } from '@/store/StoreHelper';
import CreateAccountType from '@/enums/CreateAccountType';
import { FetchData, Root, Store, t } from '@/main';
import CustomMenuDTO from '@/entities/Emits/EventSettings/CustomMenu/CustomMenuDTO';
import ShowToaster from '@/entities/Emits/EventSettings/ShowToaster';
import CustomBrandingTheme from './CustomBrandingTheme.vue';
import CustomMenuItemKeysViewModel from '@/entities/EventSetting/CustomMenuItemKeysViewModel';
import SubModulePrefix from '@/enums/SubModulePrefix';
import CustomBrandingViewModel from '@/entities/EventSetting/CustomBrandingViewModel';
import ImageProcess from '@/components/Common/ImageProcess.vue';
@Component({
  components: {
    ValidationSummary,
    CustomBrandingTheme,
    ImageProcess,
  },
})
class CustomizeMenuItemTab extends Vue {
  private localCustomMenuLanguages: LanguagesViewModel[] = [];
  private localDefaultTranslations: CustomMenuDefaultTranslationsViewModel[] = [];
  private localCustomMenuItemTranslations: any[] = [];
  private customMenuItemKeys: CustomMenuItemKeysViewModel[] = [];
  private oldLocalCustomMenuItemTranslations: any[] = [];
  private defaultLanguageId = 1;
  private isDirtyObject = true;
  private isValidationSummaryVisible = false;
  private validationErrorFields: string[] = [];
  private validationSummaryHeading = t('Warning').toString();
  private localEventDetail: ApplicationInstanceModel = ApplicationInstanceModel.createEmpty();
  private oldLocalEventDetail: ApplicationInstanceModel = ApplicationInstanceModel.createEmpty();
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private reqInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
  private myInformationId = 10;
  private showImageProcess = false;
  private isLightLogoSelected = false;
  private isMobileView = false;
  private moduleName = 'CustomBranding';
  private subModuleName = '';
  private missingImage: string = require('@/assets/Images/ImageMissingFeature.png');
  private mounted() {
    Root.on('custom-menu-dto',(customMenuData: CustomMenuDTO)=> {
      this.localCustomMenuLanguages = customMenuData.languages.sort((item1, item2) => item1.Language.toLowerCase() > item2.Language.toLowerCase() ? 1 : -1);
      this.localDefaultTranslations = customMenuData.customMenuDefaultTranslations;
      this.customMenuItemKeys = customMenuData.customMenuItemKeys;
      this.localEventDetail = Object.assign({},customMenuData.eventDetails);
      this.oldLocalEventDetail = JSON.parse(JSON.stringify(customMenuData.eventDetails));
      if(this.localEventDetail.NeedCustomMenuNames === true) {
        this.CustomizeMenuItemName();
        this.SetCustomMenuTranslatedData(this.localEventDetail.CustomMenuItemTranslations);
        this.oldLocalCustomMenuItemTranslations = JSON.parse(JSON.stringify(this.localCustomMenuItemTranslations));
      }
    });
    Root.on('save-custom-menu-names',()=> {
      this.Submit();
    });
  }
  private SetCustomMenuTranslatedData(translations: CustomMenuItemTranslations[]) {
    if(translations!== null && translations!== undefined && this.localCustomMenuItemTranslations!== null && this.localCustomMenuItemTranslations!== undefined) {
      translations.forEach((item)=> {
        const localCustomMenuItem = this.localCustomMenuItemTranslations.find(
          (menuItem) => menuItem.CustomMenuItemKeyId === item.CustomMenuItemKeyId,
        );
        if (localCustomMenuItem) {
          localCustomMenuItem.Id = item.Id;
          localCustomMenuItem.Value = item.Value;
          localCustomMenuItem.IsCheckboxChecked = true;
        }
      });
    }
  }
  private CustomizeMenuItemName() {
    // Setting default values from localDefaultTranslations on the basis of language id
    if(this.localDefaultTranslations!== null && this.localDefaultTranslations!== undefined && this.localEventDetail!== null && this.localEventDetail!== undefined) {
      if(this.localEventDetail.LanguageId === 0 && this.localEventDetail.NeedCustomMenuNames) {
        this.localEventDetail.LanguageId = this.defaultLanguageId;
      }
      this.localCustomMenuItemTranslations = this.localDefaultTranslations.filter((item) => item.LanguageId === this.localEventDetail.LanguageId).map((item) => ({Id: 0,CustomMenuItemKeyId: item.CustomMenuItemKeyId, Value: '', IsCheckboxChecked: false, SortOrder: this.GetSortOrder(item.CustomMenuItemKeyId)}));
      this.localCustomMenuItemTranslations = this.localCustomMenuItemTranslations.sort((item1, item2)=> item1.SortOrder - item2.SortOrder);
    }
  }
  private GetSortOrder(id: number) {
    const customMenuItemKey = this.customMenuItemKeys.find((item) => item.Id === id);
    return customMenuItemKey !== null && customMenuItemKey !== undefined ? customMenuItemKey.SortOrder : 0;
  }
  private ToggleCustomMenuItemInputBox(val: CustomMenuItemTranslations) {
    val.Value = '';
  }
  private GetCustomMenuItemName(customMenuItemKeyId: number) {
    // On the basis CustomMenuItemKeyId getting the corresponding menu item name
    if(this.localDefaultTranslations !== null && this.localDefaultTranslations!== undefined && customMenuItemKeyId !== null && customMenuItemKeyId !== undefined) {
      const menuItem = this.localDefaultTranslations.find(
        (item) =>
          item.CustomMenuItemKeyId === customMenuItemKeyId &&
        item.LanguageId === this.localEventDetail.LanguageId,
      );
      return menuItem!.Value;
    }
  }
  private ValidateCustomMenuNameField(customMenuItem: any) {
    return customMenuItem.Value === '' && this.validationErrorFields.includes(this.$t('EventSetting.Customization.MenuNameCanNotBeEmpty').toString());
  }
  private IsObjectDirty() {
    const objectState = (JSON.stringify(this.oldLocalCustomMenuItemTranslations) === JSON.stringify(this.localCustomMenuItemTranslations) && JSON.stringify(this.oldLocalEventDetail) === JSON.stringify(this.localEventDetail));
    this.IsTabDisable();
    if (!objectState) {
      this.IsDirtyFlag();
    }
  }
  private IsTabDisable(){
    Root.emit('disableTabs', false);
    if (this.CheckIfAnyMenuItemIsEmpty() || this.CheckIfLightModeLogoUrlIsEmpty()) {
      Root.emit('disableTabs', true);
    }
  }
  private IsDirtyFlag() {
    this.isDirtyObject = false;
    Root.emit('is-dirty', true);
  }
  private CloseValidationSummary(val: boolean) {
    this.isValidationSummaryVisible = val;
  }
  private Discard() {
    this.isDirtyObject = true;
    Root.emit('is-dirty',false);
    this.validationErrorFields = [];
    this.isValidationSummaryVisible = false;
    this.localEventDetail = JSON.parse(JSON.stringify(this.oldLocalEventDetail));
    this.localCustomMenuItemTranslations = JSON.parse(JSON.stringify(this.oldLocalCustomMenuItemTranslations));
    Root.emit('validate-light-mode-logo', false);
  }
  private ValidateData() {
    if(this.CheckIfAnyMenuItemIsEmpty()) {
      this.validationErrorFields.push(this.$t('EventSetting.Customization.MenuNameCanNotBeEmpty').toString());
    } else if(this.CheckIfLightModeLogoUrlIsEmpty()) {
      this.validationErrorFields.push(this.$t('Logo').toString());
      Root.emit('validate-light-mode-logo', true);
    } else {
      return true;
    }
  }

  private CheckIfAnyMenuItemIsEmpty() {
    if(this.localCustomMenuItemTranslations !== null && this.localCustomMenuItemTranslations !== undefined) {
      return this.localCustomMenuItemTranslations.some((item: any) => item.Value === '' && item.IsCheckboxChecked === true);
    }
  }

  // Check if light mode logo url is empty
  private CheckIfLightModeLogoUrlIsEmpty() {
    return this.reqInfo.EnableCustomBranding && this.localEventDetail.IsCustomBranding && (this.localEventDetail.CustomBrandingTheme.LightModeLogoUrl === null || this.localEventDetail.CustomBrandingTheme.LightModeLogoUrl === '') ? true : false;
  }
  private HideCustomMenuItem(customMenuItemKeyId: number) {
    return this.reqInfo.CreateAccountTypeId === CreateAccountType.AutoCreateUsernameAndPassword && customMenuItemKeyId === this.myInformationId;
  }
  // To check whether custom branding is enabled or not
  private IsCustomBrandingEnabled() {
    return this.reqInfo.EnableCustomBranding;
  }
  private SetBrandingType(val: boolean) {
    this.localEventDetail.IsCustomBranding = val;
  }
  private IsLightLogoClicked(val: boolean) {
    this.isLightLogoSelected = val;
    this.showImageProcess = true;
  }
  private SetCustomBrandingThemeData(val: CustomBrandingViewModel) {
    this.localEventDetail.CustomBrandingTheme = val;
  }
  private ShowImageProcessSection(): boolean {
    return this.showImageProcess;
  }
  private ClickOnImage(val: boolean) {
    if(!this.isMobileView) {
      this.showImageProcess = val;
      this.subModuleName = SubModulePrefix.IconImage;
    }
  }
  private GetImageUrl() {
    if(this.localEventDetail !== null && this.localEventDetail !== undefined && this.localEventDetail.CustomBrandingTheme !== null && this.localEventDetail.CustomBrandingTheme !== undefined) {
      if(this.isLightLogoSelected) {
        return (this.localEventDetail.CustomBrandingTheme.LightModeLogoUrl === null || this.localEventDetail.CustomBrandingTheme.LightModeLogoUrl === undefined || this.localEventDetail.CustomBrandingTheme.LightModeLogoUrl === '') ? this.missingImage : this.localEventDetail.CustomBrandingTheme.LightModeLogoUrl;
      } else {
        return (this.localEventDetail.CustomBrandingTheme.DarkModeLogoUrl === null || this.localEventDetail.CustomBrandingTheme.DarkModeLogoUrl === undefined || this.localEventDetail.CustomBrandingTheme.DarkModeLogoUrl === '') ? this.missingImage : this.localEventDetail.CustomBrandingTheme.DarkModeLogoUrl;
      }
    }
  }
  // Set logo image url
  private SetlogoUrl(imgurl: string, _mainMediaFullUrl: string, IsLogoUrl: boolean) {
    if(this.localEventDetail !== null && this.localEventDetail !== undefined && this.localEventDetail.CustomBrandingTheme !== null && this.localEventDetail.CustomBrandingTheme !== undefined) {
      if(IsLogoUrl === undefined || IsLogoUrl === false) {
        if(this.isLightLogoSelected) {
          this.localEventDetail.CustomBrandingTheme.LightModeLogoUrl = imgurl.includes('data:image/png') ? '' : imgurl;
        } else {
          this.localEventDetail.CustomBrandingTheme.DarkModeLogoUrl = imgurl.includes('data:image/png') ? '' : imgurl;
        }
      }
    }
  }
  private Submit() {
    this.validationErrorFields = [];
    this.isValidationSummaryVisible = false;
    Common.ScrollToTop('main-container');
    if(this.ValidateData()) {
      Root.emit('show-loader', true);
      const customMenuObject = this.localEventDetail.NeedCustomMenuNames ? this.localCustomMenuItemTranslations.filter((item) => item.Value !== '').map((item) => ({Id: item.Id,CustomMenuItemKeyId: item.CustomMenuItemKeyId, Value: item.Value})) : [];
      this.localEventDetail.CustomMenuItemTranslations = customMenuObject;
      this.localEventDetail.LanguageId = this.localEventDetail.NeedCustomMenuNames ? this.localEventDetail.LanguageId : 0;
      const objForJson = {
        Latitude: this.localEventDetail.Latitude,
        Longitude: this.localEventDetail.Longitude,
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      FetchData('/ApplicationInstance/SaveOrEditApplicationInstance',
        JSON.stringify({
          applicationInstanceModel: this.localEventDetail,
          jsonData : JSON.stringify(objForJson),
        }))
        .then((response: any) => {
          if (response.ApplicationInstance !== null && response.ApplicationInstance !== undefined) {
          // disable dirty flag
            Root.emit('is-dirty',false);
            Root.emit('event-detail-dto-feature');
            this.isDirtyObject = true;
          }
          this.oldLocalCustomMenuItemTranslations = JSON.parse(JSON.stringify(this.localCustomMenuItemTranslations));
          this.oldLocalEventDetail = JSON.parse(JSON.stringify(this.localEventDetail));
          // reload application view modal for update left side menu bar
          Root.emit('reload-GetApplicationViewModel');
          const toasterData = ShowToaster.createEmpty();
          // show hide save message and loader
          setTimeout(() => {
            toasterData.Message = this.$t('EventSetting.SuccessUpdateMessage').toString();
            toasterData.Val = true;
            Root.emit('show-loader', false);
            Root.emit('show-toaster', toasterData);
            setTimeout(() => {
              toasterData.Val = false;
              Root.emit('show-toaster', toasterData);
            }, 2000);
          }, 1000);
        })
        .catch(() => {
          Root.emit('show-loader', false);
        });
    } else {
      this.isValidationSummaryVisible = true;
    }
  }
  @Watch('localEventDetail',{deep: true})
  private CheckChangeInlocalEventDetail() {
    this.CustomizeMenuItemName();
    this.SetCustomMenuTranslatedData(this.localEventDetail.CustomMenuItemTranslations);
  }
}
export default toNative(CustomizeMenuItemTab);
