import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, vModelText as _vModelText, normalizeClass as _normalizeClass, vShow as _vShow } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "margin-top-15"
}
const _hoisted_2 = { class: "customization-tab" }
const _hoisted_3 = { class: "description margin-bottom-10" }
const _hoisted_4 = { class: "customization-menu-item-div" }
const _hoisted_5 = { class: "custom-menu-heading primary-heading" }
const _hoisted_6 = { class: "language-menu-item-container" }
const _hoisted_7 = { class: "description" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "margin-top-15" }
const _hoisted_10 = { class: "description" }
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "margin-top-25" }
const _hoisted_13 = { class: "select-menu-item" }
const _hoisted_14 = { class: "description" }
const _hoisted_15 = ["onUpdate:modelValue", "onClick"]
const _hoisted_16 = ["onUpdate:modelValue"]
const _hoisted_17 = {
  key: 1,
  class: "footer-tab"
}
const _hoisted_18 = { class: "parent-div-buttons" }
const _hoisted_19 = ["disabled"]
const _hoisted_20 = ["disabled"]
const _hoisted_21 = {
  key: 2,
  class: "image-process"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!
  const _component_CustomBrandingTheme = _resolveComponent("CustomBrandingTheme")!
  const _component_ImageProcess = _resolveComponent("ImageProcess")!

  return (_openBlock(), _createElementBlock("div", {
    class: "parent-customization-tab",
    onKeypress: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args))),
    onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args))),
    onMousemove: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args))),
    onTouchstart: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args)))
  }, [
    (_ctx.isValidationSummaryVisible && !_ctx.ShowImageProcessSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ValidationSummary, {
            "error-fields": _ctx.validationErrorFields,
            "validation-summary-heading": _ctx.validationSummaryHeading,
            onCloseClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CloseValidationSummary($event)))
          }, null, 8, ["error-fields", "validation-summary-heading"])
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("EventSetting.Customization.Description")), 1),
      (_ctx.IsCustomBrandingEnabled())
        ? (_openBlock(), _createBlock(_component_CustomBrandingTheme, {
            key: 0,
            "is-custom-branding": _ctx.localEventDetail.IsCustomBranding,
            "custom-branding-theme-data": _ctx.localEventDetail.CustomBrandingTheme,
            onSetBrandingType: _cache[1] || (_cache[1] = ($event: any) => (_ctx.SetBrandingType($event))),
            onLightLogoImageClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.IsLightLogoClicked($event))),
            onCustomBrandingThemeData: _cache[3] || (_cache[3] = ($event: any) => (_ctx.SetCustomBrandingThemeData($event)))
          }, null, 8, ["is-custom-branding", "custom-branding-theme-data"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("EventSetting.Customization.AppMenu")), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localEventDetail.NeedCustomMenuNames) = $event)),
              type: "checkbox",
              class: "checkbox-alignment",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.CustomizeMenuItemName()))
            }, null, 512), [
              [_vModelCheckbox, _ctx.localEventDetail.NeedCustomMenuNames]
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("Enable")), 1)
          ]),
          (_ctx.localEventDetail.NeedCustomMenuNames)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("EventSetting.Customization.SelectLanguage")), 1),
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t("EventSetting.Customization.SelectLanguageInstruction")), 1),
                _withDirectives(_createElementVNode("select", {
                  id: "selLanguage",
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localEventDetail.LanguageId) = $event)),
                  class: "form-control select-language selectBox"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localCustomMenuLanguages, (languageItem) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: languageItem.Id,
                      value: languageItem.Id
                    }, _toDisplayString(languageItem.Language), 9, _hoisted_11))
                  }), 128))
                ], 512), [
                  [_vModelSelect, _ctx.localEventDetail.LanguageId]
                ]),
                _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("EventSetting.Customization.SelectMenuItem")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localCustomMenuItemTranslations, (customMenuItem) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: customMenuItem.CustomMenuItemKeyId
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass([{'display-none': _ctx.HideCustomMenuItem(customMenuItem.CustomMenuItemKeyId)}, "display-flex width100 padding-5"])
                    }, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("label", _hoisted_14, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": ($event: any) => ((customMenuItem.IsCheckboxChecked) = $event),
                            type: "checkbox",
                            class: "checkbox-alignment",
                            onClick: ($event: any) => (_ctx.ToggleCustomMenuItemInputBox(customMenuItem))
                          }, null, 8, _hoisted_15), [
                            [_vModelCheckbox, customMenuItem.IsCheckboxChecked]
                          ]),
                          _createTextVNode(" " + _toDisplayString(_ctx.GetCustomMenuItemName(customMenuItem.CustomMenuItemKeyId)), 1)
                        ])
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass({'visibility-hidden': !customMenuItem.IsCheckboxChecked})
                      }, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": ($event: any) => ((customMenuItem.Value) = $event),
                          maxlength: "20",
                          type: "text",
                          class: _normalizeClass(["form-control", {'border-red': _ctx.ValidateCustomMenuNameField(customMenuItem) }])
                        }, null, 10, _hoisted_16), [
                          [
                            _vModelText,
                            customMenuItem.Value,
                            void 0,
                            { trim: true }
                          ]
                        ])
                      ], 2)
                    ], 2)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ], 512), [
      [_vShow, !_ctx.ShowImageProcessSection()]
    ]),
    (!_ctx.ShowImageProcessSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "display-flex" }, [
            _createElementVNode("div", { class: "width100" }, [
              _createElementVNode("hr", { class: "footer-hr margin-top-0" })
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                class: "btn btn-secondary btn-size-default btn-cancelevent",
                disabled: _ctx.isDirtyObject,
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.Discard()))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("Button.Discard")), 1)
              ], 8, _hoisted_19)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-size-default width100 btn-create-event",
                disabled: _ctx.isDirtyObject,
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.Submit()))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("Button.Save")), 1)
              ], 8, _hoisted_20)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowImageProcessSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createVNode(_component_ImageProcess, {
            "module-name": _ctx.moduleName,
            "sub-module-name": _ctx.subModuleName,
            "image-src": _ctx.GetImageUrl(),
            "image-full-src": _ctx.GetImageUrl(),
            "has-featured-image": false,
            "show-featured-image": false,
            "only-show-feature-image": false,
            "is-full-image": true,
            onSelectBack: _ctx.ClickOnImage,
            onSetImageIconUrl: _ctx.SetlogoUrl,
            onDiscard: _ctx.SetLogoOldUrl
          }, null, 8, ["module-name", "sub-module-name", "image-src", "image-full-src", "onSelectBack", "onSetImageIconUrl", "onDiscard"])
        ]))
      : _createCommentVNode("", true)
  ], 32))
}